import { useContext, useEffect, useState } from "react";

import { FormType } from "@simplyk/common";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

import { IS_PREVIEW_QUERY_PARAM } from "../../../constants/url";
import { useCurrentUserContext } from "../../../contexts/CurrentUserContext";
import { FrontendFormContext } from "../../../contexts/FrontendFormContext";
import { useIsIframe } from "../../../hooks/useIsIframe";
import { useIsOnboarding } from "../../../hooks/useIsOnboarding";

export const useShouldDisplayFormOwnerBanner = () => {
  const { organization: userOrg, userIsLoading } = useCurrentUserContext();
  const { formType, formData } = useContext(FrontendFormContext);
  const search = useSearchParams();
  const router = useRouter();
  const { isOnboarding } = useIsOnboarding();
  const isIframe = useIsIframe();
  const [isPeerToPeerCreator, setIsPeerToPeerCreator] = useState(false);

  useEffect(() => {
    // Only access localStorage in the browser
    if (typeof window !== "undefined") {
      const storedData: { formId: string; token: string } = JSON.parse(
        localStorage.getItem("peer-to-peer-form-data") || "{}"
      );
      setIsPeerToPeerCreator(storedData.formId === formData.id && !!storedData.token);
    }
  }, [formData.id]);

  const isPreviewTemplateMode = router.query.previewTemplateMode === "true";
  const isPreview = search.get(IS_PREVIEW_QUERY_PARAM) === "true" || false;

  const formOrg = formData.organization;
  const isOwner = formOrg && userOrg && formOrg?.id === userOrg.id;

  const paymentFormTypes = [FormType.DonationForm, FormType.Ticketing];
  const isPaymentForm = formType && paymentFormTypes.includes(formType);

  const isFormPageDonorView = formData.id;

  return {
    isLoading: userIsLoading,
    isPeerToPeerCreator,
    value:
      (isOwner || isPeerToPeerCreator) &&
      !isOnboarding &&
      !isPreviewTemplateMode &&
      !isPreview &&
      !isIframe &&
      isPaymentForm &&
      isFormPageDonorView,
  };
};
