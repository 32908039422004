import { FunctionComponent, useMemo, useState, useCallback, useRef, useEffect, useContext } from "react";

import {
  StripeRecurringInterval,
  getCurrencyFromCountry,
  convertTimestampToDateNumber,
  Undefinable,
  Utils,
  Emptyable,
  ProductDonationRecurrenceInterval,
  PaymentMethod,
} from "@simplyk/common";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";
import { v4 } from "uuid";

import { useComputeTip } from "../components/PaymentProcessor/hooks/useComputeTip";
import { AmplitudeEvents } from "../constants/amplitude";
import { IS_PREVIEW_QUERY_PARAM } from "../constants/url";
import { CurrentUserContext } from "../contexts/CurrentUserContext";
import { FrontendFormContext } from "../contexts/FrontendFormContext";
import { useLocaleContext } from "../contexts/LocaleContext";
import { OrganizationObject, FormType, Locales, TicketingFormCategory, FeatureFlagName } from "../gql/gql-types";
import { setUserProperty } from "../helpers/amplitude";
import { getDonationFormFeatureFlagTreatment } from "../helpers/feature-flag";
import { isDonationForm, isTicketing } from "../helpers/form";
import {
  doesPaymentMethodAllowTip,
  isPaymentMethodAlwaysComplete,
  listPaymentMethods,
  ONE_TIME_PAD_MIN,
} from "../helpers/payment-method";
import { trpc } from "../helpers/trpc";
import { useAmplitude } from "../hooks/amplitude/useAmplitude";
import { useTranslationField } from "../hooks/useDonationTranslation";
import { useFormAmplitudeContext, useLogFormAmplitude } from "../hooks/useFormAmplitudeContext";
import { useFormVisited } from "../hooks/useFormVisited";
import { useIsOnboarding } from "../hooks/useIsOnboarding";
import { useSupportsExpressCheckout } from "../hooks/useSupportsExpressCheckout";
import { useUpsertSession } from "../hooks/useUpsertSession";

import { getStripeSubscriptionRecurrence } from "@/components/PaymentProcessor/helper";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

interface FrontendFormProvider {
  formData: DonationFormOutput | TicketingOutput;
  organization: OrganizationObject;
  formType: FormType;
  isEmbed: boolean;
  isFundraiser: boolean;
  shouldDisplayLogin: boolean;
  themeColor?: Emptyable<string>;
}

export const FrontendFormProvider: FunctionComponent<React.PropsWithChildren<FrontendFormProvider>> = ({
  children,
  formData,
  organization,
  formType,
  isEmbed,
  isFundraiser,
  shouldDisplayLogin,
  themeColor,
}) => {
  const router = useRouter();
  const firstUpdate = useRef(true);
  const { logAmplitudeEvent } = useAmplitude();
  const { mutate: updatePreviewFirstFormAt } = trpc.updatePreviewFirstFormAt.useMutation();

  const { isOnboarding } = useIsOnboarding();
  const { locale } = useLocaleContext();
  const { amount: defaultAmount } = router.query as unknown as { amount: string };
  const search = useSearchParams();
  const isPreviewTemplateMode = search.get("previewTemplateMode") === "true" || false;
  const isPreview = search.get(IS_PREVIEW_QUERY_PARAM) === "true" || false;

  const { organization: loggedOrganization } = useContext(CurrentUserContext);

  const organizationCreatedAtDate = organization.createdAtUtc
    ? convertTimestampToDateNumber(organization.createdAtUtc)
    : "undefined";

  const defaultDonationRecurrence = isDonationForm(formData)
    ? (formData.donationFormFields?.[0]?.donationFormAmounts?.[0]?.recurrenceInterval as
        | ProductDonationRecurrenceInterval
        | undefined) || ProductDonationRecurrenceInterval.OneTime
    : undefined;

  const displayAddressQuestion = isDonationForm(formData)
    ? (formData.displayAddressQuestion ?? true)
    : isTicketing(formData)
      ? (formData.displayAddressQuestion ?? true)
      : false;

  const askForAddress = isDonationForm(formData)
    ? (formData.askForAddress ?? true)
    : isTicketing(formData)
      ? (formData.askForAddress ?? true)
      : false;

  const defaultRecurrence = defaultDonationRecurrence
    ? getStripeSubscriptionRecurrence(defaultDonationRecurrence)
    : null;

  const [stripeRecurrenceInterval, setStripeRecurrenceInterval] = useState<StripeRecurringInterval | null>(
    defaultRecurrence
  );

  const isAuction = isTicketing(formData) && formData?.formCategory === TicketingFormCategory.Auction;
  const paymentMode = isAuction ? "setup" : stripeRecurrenceInterval ? "subscription" : "payment";

  const {
    expressCheckoutDisabled,
    isExpressCheckoutLoading,
    supportsExpressCheckout,
    showExpressCheckoutLayout,
    handleSetSupportsExpressCheckout,
  } = useSupportsExpressCheckout(paymentMode);

  const defaultSelectedPaymentMethod =
    expressCheckoutDisabled || isPreviewTemplateMode ? PaymentMethod.Card : undefined;

  const commandId = useMemo(() => v4(), []);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<{
    paymentMethod: Undefinable<PaymentMethod>;
    complete: boolean;
  }>({
    paymentMethod: defaultSelectedPaymentMethod,
    complete: false,
  });

  /**
   * Set the donor_organization_id and donor_organization_creation_date properties in amplitude.
   */
  useEffect(() => {
    if (organization.id) {
      setUserProperty("donor_organization_id", organization.id);
      setUserProperty("donor_organization_country", organization.country);
      setUserProperty("donor_organization_region", organization.region || "undefined");
      setUserProperty("donor_organization_creation_date", organizationCreatedAtDate);
    }
  }, [organizationCreatedAtDate, organization.id, organization.country, organization.region]);

  useEffect(() => {
    if (loggedOrganization && !loggedOrganization.previewFirstFormAt && !isPreview && !isPreviewTemplateMode) {
      updatePreviewFirstFormAt();
    }
  }, [isPreview, isPreviewTemplateMode, loggedOrganization, organization.previewFirstFormAt, updatePreviewFirstFormAt]);

  const [displayedFormAmount, setDisplayedFormAmount] = useState<number>(parseInt(defaultAmount) * 100 || 0);
  const [canUsePaymentRequest, setCanUsePaymentRequest] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

  const translationLocales = getTranslationLocales(formData, formType, locale);
  const { field, fieldLength, postTransactionUrl, chequeDescription } = useTranslationField(formData);
  const { formAmplitudeContext } = useFormAmplitudeContext({
    formData,
    field,
    isEmbed,
    organization,
    selectedPaymentMethod: selectedPaymentMethod.paymentMethod,
  });
  const currency = getCurrencyFromCountry(organization?.country);
  const isMembershipV2 = isTicketing(formData) && formData?.formCategory === TicketingFormCategory.MembershipV2;
  const isArchived = isDonationForm(formData) || isTicketing(formData) ? formData.isArchived : false;
  const category = isDonationForm(formData) ? formData.category : formData.formCategory;

  const isFreeTicketing = Boolean(
    isTicketing(formData) && formData.rates?.every((rate) => rate.amount === 0) && !formData.extraDonation
  );

  const {
    tip,
    suggestedTips,
    selectedTip,
    isSuggestedTipsAmount,
    handleChangeTips,
    handleChangeFreeTips,
    resetTipToCoverBankCharge,
    freeTips,
    updateFreeTipAmount,
    fees,
    formattedFees,
    tipExperimentValue,
    getTipChoice,
    logAmplitudeTipsChanged,
  } = useComputeTip({
    displayedFormAmount,
    currency,
    organizationCountry: organization?.country,
    paymentMethod: selectedPaymentMethod.paymentMethod,
    isAuction,
    category,
    formData,
  });

  useFormVisited({ formData, formType });
  useLogFormAmplitude({ formData, formAmplitudeContext });
  useUpsertSession({ displayedFormAmount, tipSuggestions: suggestedTips, isSuggestedTipsAmount, tipExperimentValue });

  const isPaymentByPadAllowedByFeatureFlag = isDonationForm(formData)
    ? (getDonationFormFeatureFlagTreatment(formData.featureFlagTreatments, FeatureFlagName.IsPaymentByPadAllowed) ??
      false)
    : isTicketing(formData)
      ? Boolean(formData.ticketingFeatureFlagTreatment?.isPaymentByPadAllowed)
      : false;

  const handleSetSelectedPaymentMethod = useCallback(
    (paymentMethod: Undefinable<PaymentMethod>, complete?: boolean) => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
      } else if (paymentMethod !== selectedPaymentMethod.paymentMethod) {
        logAmplitudeEvent(AmplitudeEvents.DonorFormPaymentMethodChanged, {
          paymentMethod,
          formType,
          formId: formData.id,
          organizationCountry: organization.country,
          formCategory: category,
          feesCovered: displayedFormAmount >= ONE_TIME_PAD_MIN && !tip,
        });
      }
      setSelectedPaymentMethod({
        paymentMethod,
        complete: complete ?? isPaymentMethodAlwaysComplete(paymentMethod),
      });
    },
    [
      category,
      displayedFormAmount,
      formData.id,
      formType,
      logAmplitudeEvent,
      organization.country,
      selectedPaymentMethod.paymentMethod,
      tip,
    ]
  );

  const allowedPaymentMethods = useMemo(
    () =>
      listPaymentMethods({
        formData,
        stripeRecurrenceInterval,
        organizationCountry: organization.country,
        totalAmount: displayedFormAmount,
        formType,
        areBankPaymentMethodsAllowed: isPaymentByPadAllowedByFeatureFlag,
        orgCardMaximumAmount: organization.cardMaximumAmount || Infinity,
        isAuction,
        isStripeCustomAccountActive: organization.isStripeCustomAccountActive,
        isPreviewTemplateMode,
        isCashappEnabled: false,
      }),
    [
      displayedFormAmount,
      formData,
      formType,
      isAuction,
      isPaymentByPadAllowedByFeatureFlag,
      isPreviewTemplateMode,
      organization.cardMaximumAmount,
      organization.country,
      organization.isStripeCustomAccountActive,
      stripeRecurrenceInterval,
    ]
  );

  const applePayOrGooglePayAllowed = useMemo(
    () => allowedPaymentMethods.includes(PaymentMethod.ApplePayOrGooglePay),
    [allowedPaymentMethods]
  );

  const achAllowed = useMemo(() => allowedPaymentMethods.includes(PaymentMethod.Ach), [allowedPaymentMethods]);

  const padAllowed = useMemo(() => allowedPaymentMethods.includes(PaymentMethod.Pad), [allowedPaymentMethods]);

  const prioritizeBankPayment = displayedFormAmount >= ONE_TIME_PAD_MIN;

  const expressCheckoutIsSelected =
    supportsExpressCheckout && selectedPaymentMethod.paymentMethod === PaymentMethod.ApplePayOrGooglePay;

  const generateETicket =
    category &&
    [
      TicketingFormCategory.Event,
      TicketingFormCategory.Lottery,
      TicketingFormCategory.Custom,
      TicketingFormCategory.Shop,
    ].includes(category as TicketingFormCategory) &&
    isTicketing(formData) &&
    formData.generateQrCode;

  const displayExpressCheckout = applePayOrGooglePayAllowed && supportsExpressCheckout && !prioritizeBankPayment;

  const showTopBillingInfo = (!isExpressCheckoutLoading && !displayExpressCheckout) || isPreviewTemplateMode;
  const showBottomBillingInfo =
    showExpressCheckoutLayout &&
    Boolean(selectedPaymentMethod.paymentMethod) &&
    selectedPaymentMethod.paymentMethod !== PaymentMethod.ApplePayOrGooglePay &&
    !showTopBillingInfo;

  const isPaymentDisabled = Boolean(organization.isPaymentDisabled);

  return (
    <FrontendFormContext.Provider
      value={{
        isFreeTicketing,
        defaultSelectedPaymentMethod,
        formData,
        isArchived,
        field,
        isOnboarding,
        organization,
        commandId,
        formType,
        category,
        themeColor,
        translationLocales,
        isEmbed,
        isFundraiser,
        isFormValid,
        setIsFormValid,
        isFormDirty,
        setIsFormDirty,
        shouldDisplayLanguageSelect: Boolean(fieldLength && fieldLength > 1 && !isOnboarding),
        shouldDisplayLogin: shouldDisplayLogin && !isOnboarding,
        formAmplitudeContext,
        selectedPaymentMethod: selectedPaymentMethod.paymentMethod,
        setSelectedPaymentMethod: handleSetSelectedPaymentMethod,
        currency,
        stripeRecurrenceInterval,
        setStripeRecurrenceInterval,
        tip,
        suggestedTips,
        selectedTip,
        isSuggestedTipsAmount,
        handleChangeTips,
        handleChangeFreeTips,
        resetTipToCoverBankCharge,
        freeTips,
        updateFreeTipAmount,
        fees,
        formattedFees,
        displayTipSelection: Boolean(
          doesPaymentMethodAllowTip(selectedPaymentMethod.paymentMethod) && displayedFormAmount > 0
        ),
        displayedFormAmount,
        setDisplayedFormAmount,
        displayedFormAmountWithTip: displayedFormAmount + tip,
        isMembershipV2,
        canUsePaymentRequest,
        setCanUsePaymentRequest,
        paymentRequest,
        setPaymentRequest,
        areBankPaymentMethodsAllowed: padAllowed || achAllowed,
        allowedPaymentMethods,
        postTransactionUrl,
        prioritizeBankPayment,
        selectedPaymentMethodIsBank:
          selectedPaymentMethod.paymentMethod === PaymentMethod.Ach ||
          selectedPaymentMethod.paymentMethod === PaymentMethod.Pad,
        selectedPaymentMethodIsCard:
          selectedPaymentMethod.paymentMethod === PaymentMethod.Card ||
          selectedPaymentMethod.paymentMethod === PaymentMethod.ApplePayOrGooglePay,
        chequeDescription,
        showProceedButton:
          selectedPaymentMethod.paymentMethod === PaymentMethod.Pad ||
          (selectedPaymentMethod.paymentMethod === PaymentMethod.Ach && selectedPaymentMethod.complete),
        displayExpressCheckout,
        handleSetSupportsExpressCheckout,
        isExpressCheckoutLoading,
        isSubmitting,
        setIsSubmitting,
        expressCheckoutIsSelected,
        showTopBillingInfo,
        showBottomBillingInfo,
        expressCheckoutDisabled,
        paymentMode,
        isAuction,
        shouldSendTipPercentage: isAuction && !selectedTip.isFree && selectedTip.percentage !== 0,
        getTipChoice,
        logAmplitudeTipsChanged,
        displayAddressQuestion,
        generateETicket,
        isPreviewTemplateMode,
        isPreview,
        isPaymentDisabled,
        askForAddress,
      }}
    >
      {children}
    </FrontendFormContext.Provider>
  );
};

const getTranslationLocales = (
  formData: Undefinable<DonationFormOutput | TicketingOutput>,
  formType: Undefinable<FormType>,
  locale: Locales
) => {
  return formType === FormType.DonationForm
    ? Utils.filterAndMap(
        (formData as DonationFormOutput).donationFormFields || [],
        (field) => field.locale !== locale,
        (field) => field.locale
      )
    : formType === FormType.Ticketing
      ? Utils.filterAndMap(
          (formData as TicketingOutput).ticketingFields || [],
          (field) => field.locale !== locale,
          (field) => field.locale
        )
      : [];
};
